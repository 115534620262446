<script>
import Detail from "./detail";
export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listProdutos_confeccao: { type: Array, required: true },
    produtos: { type: Array },
    hide: { type: Boolean, required: true },
  },
  components: { Detail },
  data() {
    return {
      array_produtos:[],
      titleBody: "Listagem de Produtos Confecções",
      current_prod_confc: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "Numero",
          key: "id",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Referencia",
          key: "referencia",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Nome",
          key: "descricao",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Quantidade",
          key: "quantidade",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Data Cadastro",
          key: "dt_cadastro",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Processado",
          key: "processado_id",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Previsão de Conclusão:",
          key: "dt_conclusao",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Data de Cadastro ",
          key: "dt_cadastro",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listProdutos_confeccao.length
        ? this.listProdutos_confeccao.length
        : 0;
    },
    clonefoot() {
      return this.listProdutos_confeccao.length ||
        this.listProdutos_confeccao.length > 10
        ? true
        : false;
    },
  },
  mounted() {
    this.$emit("newTitle", this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    processando(objeto) {
      console.log(objeto);
      let produto_selecioando = this.produtos.find((p)=> p.id == objeto.produto_id)
      objeto.vetor_compoe = produto_selecioando.compoe_produtos
      console.log("Mosntrando quantiade ao processaor Produto")
      console.log(objeto)
      this.$emit("processando", objeto);
    },
    editar(produto_confecao) {
      this.$emit("edit", produto_confecao);
    },
    excluir(produto_confecao) {
      console.log("monstrando objeto do exluir Confeção");
      console.log(produto_confecao)
      let produto_selecioando = this.produtos.find((p)=> p.id == produto_confecao.produto_id)
      produto_confecao.vetor_compoe = produto_selecioando.compoe_produtos
      console.log(produto_confecao)
      this.$emit("doDelete", produto_confecao);
    },

    cancelando(objeto){
      console.log("estou na função de cancelamento")
      console.log(objeto)
      let objeto1 = this.produtos.find((p)=>p.id == objeto.produto_id)
      objeto.produtos = this.produtos.find((p)=>p.id == objeto.produto_id)
      objeto.compoe_produtos = objeto1.compoe_produtos
      console.log(objeto.compoe_produtos)
      this.$emit('cancelando',objeto)
    },
    setProduto_confec(produto_confecao) {
      this.current_prod_confc = produto_confecao;
      console.log(this.current_prod_confc)
    },
  },
};
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="historico-padrao-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div
          id="historico-padrao-table_filter"
          class="dataTables_filter text-md-right"
        >
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4">
      <div v-if="hide" class="table-responsive mb-0">
        <b-table
          :items="listProdutos_confeccao"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :foot-clone="clonefoot"
        >
          <template #cell(user_name)="row">
            {{
              row.item.user ? row.item.user.name : "Usuário não especificado"
            }}
          </template>
          <template #cell(status)="row">
            {{ row.item.status === 1 ? "Sim" : "Não" }}
          </template>
          <template #cell(processado_id)="row">
            <b-badge
              data-name="Total"
              class="field-status"
              :variant="row.item.processado_id == 1 ? 'success' : 'danger'"
            >
              {{ row.item.processado_id == 1 ? 'processado' : 'Não processado' }}
            </b-badge>
          </template>

          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item
                v-b-modal.modal-produto-confec
                @click="setProduto_confec(row.item)"
                ><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item
              >
              <b-dropdown-item @click="editar(row.item)"
                ><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item
              >
              <b-dropdown-item v-if="row.item.processado_id == 0" @click="processando(row.item)"
                ><i class="bx ri-edit-2-line"></i> Processar</b-dropdown-item
              >
              <b-dropdown-item v-if="row.item.processado_id == 1" @click="cancelando(row.item)"
                ><i class="bx ri-edit-2-line"></i> Cancelar1</b-dropdown-item
              >
              <b-dropdown-item @click="excluir(row.item)"
                ><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Centro Custo -->
    <b-modal
      v-if="hide"
      hide-footer
      id="modal-produto-confec"
      :title="'Produtos Confecções'"
      title-class="font-18"
      size="xl"
    >
      <Detail :produto_confec="current_prod_confc" :array_produtos="listProdutos_confeccao" :produtosGeral="produtos" />
    </b-modal>
    <!-- End Modal Centro Custo -->
  </div>
</template>