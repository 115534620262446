<script>
import appConfig from "@/app.config";
import { http } from "@/helpers/easyindustriaapi/config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  page: {
    title: "Confecção Produtos",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, List, Insert, Edit },
  data() {
    return {
      locais_estoques: [],
      currentProdutocofeccao: {},
      produto_confeccoes: [],
      inserindo: false,
      editando: false,
      cidades_ibge1: [],
      hideSearches: false,
      visualizando: false,
      time: {
        date_one: "",
        date_two: "",
      },
      produtos2: [],

      submited: false,

      titleBody: "Confeção de Produtos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Processos",
          href: "/",
          active: true,
        },
        {
          text: "Confecção de Produtos",
          href: "/processos/confeccaoProduto",
          active: true,
        },
      ],
      t_estoques: [],
    };
  },
  computed: {
    isHide() {
      return !this.inserindo && !this.editando;
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    this.getData();
    this.cidades_ibge();
    this.getProdutos2();
    this.get_local_estoque();
  },
  methods: {
    async getProdutos2() {
      this.onLoader();

      try {
        let response = await http.get(
          "/produto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.produtos2 = response.data;
        } else {
          this.produtos2 = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    view() {
      if (this.visualizando) {
        this.visualizando = true;
      } else {
        this.visualizando = !this.visualizando;
      }
    },
    searchInputs1() {
      if (this.hideSearches) {
        this.hideSearches = false;
      }
    },
    searchInputs() {
      this.hideSearches = !this.hideSearches;
    },

    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.inserindo = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },

    async cidades_ibge() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/distritos"
        );

        if (response.status === 200) {
          this.cidades_ibge1 = response.data;
          // console.log("ibge");
          // console.log(this.cidades_ibge1)

          // this.cidades_ibge1.forEach((cidade) => {

          //   console.log(cidade.municipio)

          // });
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getSearchTime() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.post("/produtoconfec/show", this.time);
        //  console.log(response)

        if (response.status === 200) {
          this.produto_confeccoes = response.data;
          //console.log(this.produto_confeccoes);
          //(this.produto_confeccoes)
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    async getData() {
      this.onLoader();

      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "/compoeproduto?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.produto_confeccoes = response.data;
          //console.log("estou no compoe")
          //console.log(this.produto_confeccoes)
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    clear() {
      this.time.date_one = "";
      this.time.date_two = "";
    },
    edit(historico) {
      //console.log(historico);
      this.currentProdutocofeccao = historico;
      this.editando = !this.editando;
    },
    async get_estoques_all() {
      try {
        let response = await http.get(
          "/estoque?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.t_estoques = response.data;
          //console.log(this.p_contas);
          this.gerar_pdf();
          setTimeout(() => {
            //this.offLoader();
          }, 800); // tempo para carregar a tela;
        } else {
          this.t_estoques = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    gerar_pdf() {
      this.makeToast("sucess", "Gerando PDF");
      let tamanho = this.t_estoques.length;
      const doc = new jsPDF();
      const titulo = "Estoque";
      const header = [
        "Nome",
        "quantidade",
        "Quantidade Anterior",
        "Quantidade Atual",
        "local Estoque",
      ];
      const footer = ["Total Geral", `${tamanho}`, ""];
      // Adicionar o header e o foote
      doc.text(titulo, 80, 10);
      // Array para armazenar as linhas da tabela
      const linhasDaTabela = [];
      // Preencher o array com linhas para cada objeto
      this.t_estoques.forEach(function (objeto) {
        const linha = [
          objeto.descricao,
          objeto.qtde,
          objeto.qtdeold,
          objeto.qtdenew,
          objeto.local_estoque_id,
        ];
        linhasDaTabela.push(linha);
      });
      doc.autoTable({
        head: [header],
        body: linhasDaTabela,
        foot: [footer],
      });
      // this.clientes.forEach(function (dado) {
      //     doc.autoTable({
      //         body: [dado],
      //     });
      // });
      // Salvar o documento
      doc.save("relatorio.pdf");
    },
  // subindo front end novamente
    async cancelando(objeto) {
      console.log("esotu no index canceladno ");
      console.log(objeto);
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id
      //this.onLoader();
      objeto.processado_id =0;
      this.doPut(objeto);
      try {
        let response = await http.post(
          "/compoeproduto/cancelar?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
      
          // this.offLoader();
          if (response.status === 200) {
            this.makeToast("success", "Registro incluído");
            this.makeToast("info", "entrou ")
          }
        
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },

    async doPost(confec) {
      //console.log(cidade);
      confec.empresa_id = this.currentEmpresa.id;
      confec.user_id = this.currentUser.id;
      let response = await http
        .post("/compoeproduto?empresa_id=" + this.currentEmpresa.id, confec)
        .catch((error) => {
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro incluído");
      }
    },
    async processando(confec) {
      this.makeToast("info", "esotu ma função de processametno");
      //console.log(cidade);
      // confec.empresa_id = this.currentEmpresa.id;
      // confec.user_id = this.currentUser.id;
      console.log("objeto de do processamento");
      confec.processado_id = 1;
      this.doPut(confec);
      console.log(confec);
      let response = await http
        .post(
          "compoeproduto/processar?empresa_id=" + this.currentEmpresa.id,
          confec
        )
        .catch((error) => {
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro incluído");
      }
    },
    async doPut(confec) {
      console.log("entramos aqui no put");
      console.log(confec);
      this.onLoader();
      confec.user_id = this.currentUser.id;
      let response = await http
        .put(
          "/compoeproduto/" +
            confec.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          confec
        )
        .catch((error) => {
          this.offLoader();
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast("success", "Registro alterado");
      }
    },
    async doDelete(confec) {
      this.onLoader();
      confec.empresa_id = this.currentEmpresa.id;
      let response = await http
        .delete(
          "/compoeproduto/" +
            confec.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          confec
        )
        .catch((error) => {
          this.makeToast("danger", error.response);
          this.offLoader();
        });

      if (response.status === 200) {
        console.log("mostrando valor de Retorno Delete")
        console.log(response.data)
        this.getData();
        this.back();
        this.makeToast("warning", "Registro excluído");
      }
    },
    async get_local_estoque() {
      this.onLoader();

      try {
        let response = await http.get(
          "/localestoque?empresa_id=" + this.currentEmpresa.id
        );

        if (response.status === 200) {
          this.locais_estoques = response.data;
          // console.log('locais estoques')
          // console.log(this.locais_estoques)
          setTimeout(() => {
            this.offLoader();
          }, 1000); // tempo para carregar a tela;
        } else {
          this.locais_estoques = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          this.offLoader();
        }
      } catch (error) {
        this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <!-- Start Bar Buttons -->
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <button
                  v-if="isHide"
                  class="btn btn-light"
                  @click="searchInputs()"
                >
                  <span
                    class="fa-sm fa"
                    :class="hideSearches ? 'fa-chevron-up' : 'fa-chevron-down'"
                  ></span>
                  Busca avançada
                </button>
              </div>
              <div class="col-sm-12 col-md-12 text-md-right">
                <button
                  v-if="isHide"
                  class="btn btn-primary mr-1"
                  @click="insert(), searchInputs1()"
                >
                  + Incluir
                </button>
                <!-- <button v-if="isHide" class="btn btn-primary mr-1" >+ Alterar</button> -->
                <button
                  v-if="isHide"
                  @click="get_estoques_all()"
                  class="btn btn-success mr-1"
                >
                  + Exportar Pdf
                </button>
                <button v-if="isHide" class="btn btn-success">
                  + Importar XLSX
                </button>
                <button
                  v-if="!isHide"
                  class="btn btn-secondary"
                  @click="back()"
                >
                  Voltar
                </button>
              </div>
            </div>
          </div>

          <div class="card-body" v-if="hideSearches">
            <!-- Start Card -->
            <div class="card border border-primary">
              <div class="card-body">
                <h4 class="card-title">Filtros</h4>
                <p class="card-title-desc">
                  Use esses campos para filtrar os registros
                </p>
                <form class="needs-validation" name="search">
                  <div class="row">
                    <!-- <div class="col-md-3">
                      <div class="form-group">
                         <label for="searchCompras-status">Nº Documento</label> 
                        <div class="row">
                          <div class="col-md-6">
                            <input id="searchCompras-fromNNf"  type="number"
                              class="form-control" placeholder="Nº NF" /> 
                          </div>
                          <div class="col-md-6">
                            <input id="searchCompras-toNNf"  type="number"
                              class="form-control" placeholder="Nº NF" /> 
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="searchCompras-fromData">Data saída</label>
                        <div class="row">
                          <div class="col-md-6">
                            <!-- type="datetime-local" -->
                            <input
                              v-model="time.date_one"
                              id="searchCompras-fromData"
                              type="date"
                              class="form-control"
                              placeholder="dd/mm/aaaa hh:mm"
                            />
                          </div>
                          <div class="col-md-6">
                            <input
                              v-model="time.date_two"
                              id="searchCompras-toData"
                              type="date"
                              class="form-control"
                              placeholder="dd/mm/aaaa hh:mm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <!-- <div class="form-group">
                        <label>Fornecedor</label>
                        <input id="searchCompras-destinatario"  type="text"
                          class="form-control" placeholder="Nome do Fornecedor" />
                      </div> -->
                    </div>
                  </div>
                  <button
                    @click="getSearchTime()"
                    class="btn btn-primary"
                    type="button"
                  >
                    OK
                  </button>
                  <button
                    id="leftt"
                    @click="clear()"
                    class="btn btn-primary"
                    type="button"
                  >
                    limpar
                  </button>
                </form>
              </div>
            </div>
            <!-- End Card -->
          </div>
          <!-- End Bar Buttons -->
          <div class="card-body" v-if="!isHide">
            <Insert
              v-if="inserindo"
              :produtos2="produtos2"
              :empresa="currentEmpresa"
              :ibge="cidades_ibge1"
              :locais_estoques="locais_estoques"
              @newTitle="changeHomeTitle"
              @doPost="doPost"
            />
            <Edit
              v-if="editando"
              :empresa="currentEmpresa"
              :oldConfecao="currentProdutocofeccao"
              @newTitle="changeHomeTitle"
              @doPut="doPut"
              :locais_estoques="locais_estoques"
              :produtos2="produtos2"
            />
          </div>

          <List
            v-if="isHide"
            :currentEmpresa="currentEmpresa"
            :listProdutos_confeccao="produto_confeccoes"
            :hide="isHide"
            :produtos="produtos2"
            @newTitle="changeHomeTitle"
            @edit="edit"
            @doDelete="doDelete"
            @processando="processando"
            @cancelando="cancelando"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>


<style scoped>
#leftt {
  margin-left: 5px;
}
</style>
